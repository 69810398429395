import React, { SVGProps } from 'react';

export default function TwilioLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="50" viewBox="0 0 570.789 357.211" {...props}>
      <g id="Groupe_304" data-name="Groupe 304" transform="translate(1788.593 507.301)" opacity="0.996">
        <g id="Groupe_57" data-name="Groupe 57" transform="translate(-1782.519 -241.775)">
          <path
            id="Tracé_4487"
            data-name="trace-4487"
            d="M-1773.125-215.439h-10.851q.063-3.946.2-14.074t.132-29.071q0-7.5-.065-19.664t-.263-25.256h10.851q-.129,9.406-.228,21.867t-.1,20.357q0,19.993.131,30.943T-1773.125-215.439Z"
            transform="translate(1783.976 306.07)"
            fill="#282828"
          />
          <path
            id="Tracé_4488"
            data-name="trace-4488"
            d="M-1696.653-220.186h-10.392q.062-1.839.164-12.166t.1-12.76q0-3.418-.031-10.1t-.1-9.765q-.2-7.561-2.893-10.853t-9.34-3.289a27.849,27.849,0,0,0-9.932,2.368,80.937,80.937,0,0,0-11.971,6.05q0,.726-.065,7.829t-.065,12.956q0,7.631.165,18.416t.164,11.312h-10.393q0-.458.133-8.057t.131-22.591q0-6.906-.033-16.18t-.231-19.731h10.261l-.066,7.565.066.131a91.621,91.621,0,0,1,15.358-7.335,33.4,33.4,0,0,1,10.227-2.072q8.418,0,13.515,5.3t5.1,14.5q0,8.286-.1,14.3t-.1,9.767q0,5.985.166,14.963T-1696.653-220.186Z"
            transform="translate(1794.303 310.818)"
            fill="#282828"
          />
          <path
            id="Tracé_4489"
            data-name="trace-4489"
            d="M-1676.489-294.142h-11.575v-11.313h11.575Zm-.59,79.319h-10.393q.063-1.05.165-12.955t.1-19.666q0-6.775-.035-14.9t-.229-19.041h10.393q-.135,9.472-.231,17.725t-.1,14.7q0,15.326.164,24.6T-1677.078-214.823Z"
            transform="translate(1814.235 305.455)"
            fill="#282828"
          />
          <path
            id="Tracé_4490"
            data-name="trace-4490"
            d="M-1619.107-225.013l-1.183,8.353c-.309.043-1.064.086-2.269.132s-2.247.064-3.125.064q-13.482,0-20.388-5.622t-6.905-17.266q0-7.3.1-15.653t.1-20.716h-11.051v-7.7h11.643V-299h9.535v15.586h23.545v7.7h-23.545q0,1.381-.1,8.219t-.1,10.129v16.311q0,8.554,4.6,12.331t14.2,3.785c.789,0,1.732-.013,2.829-.034S-1619.414-225.013-1619.107-225.013Z"
            transform="translate(1821.88 307.49)"
            fill="#282828"
          />
          <path
            id="Tracé_4491"
            data-name="trace-4491"
            d="M-1601.926-294.142H-1613.5v-11.313h11.574Zm-.592,79.319h-10.39q.062-1.05.164-12.955t.1-19.666q0-6.775-.034-14.9t-.229-19.041h10.39q-.131,9.472-.229,17.725t-.1,14.7q0,15.326.166,24.6T-1602.518-214.823Z"
            transform="translate(1837.759 305.455)"
            fill="#282828"
          />
          <path
            id="Tracé_4492"
            data-name="trace-4492"
            d="M-1535.463-257.9q0,16.313-.065,26.571t-.066,11.049q-.66.066-6.117.493t-14.074.428a60.9,60.9,0,0,1-10.853-.985,38.371,38.371,0,0,1-9.405-2.83,22.456,22.456,0,0,1-8.519-6.641,16.075,16.075,0,0,1-3.256-10.129,16.262,16.262,0,0,1,3.849-10.82,27.279,27.279,0,0,1,10.687-7.4,62.354,62.354,0,0,1,11.412-3.192,142.846,142.846,0,0,1,16.279-2.135,17.537,17.537,0,0,0-2.566-8.418,13.427,13.427,0,0,0-6.38-5,26.714,26.714,0,0,0-6.445-1.68,61.217,61.217,0,0,0-8.288-.492q-2.961,0-5.033.131t-5.622.33l1.053-8.749q.987-.063,3.913-.229t7.991-.166a54.18,54.18,0,0,1,13.845,1.613,23.948,23.948,0,0,1,10.1,5.228,21.614,21.614,0,0,1,5.656,8.452Q-1535.463-267.434-1535.463-257.9Zm-10.065,15.852V-247.7q0-3.42-.064-7.893-2.895.132-10.129,1.15a55.582,55.582,0,0,0-12.168,2.928,18.907,18.907,0,0,0-6.546,4.141,9.138,9.138,0,0,0-2.859,6.84,10.414,10.414,0,0,0,2.433,7.2,15.353,15.353,0,0,0,6.642,4.18,35.091,35.091,0,0,0,7.137,1.609,65.794,65.794,0,0,0,8.254.5q1.314,0,3.222-.069l4.013-.131q0-.458.034-4.34T-1545.527-242.044Z"
            transform="translate(1845.863 311.039)"
            fill="#282828"
          />
          <path
            id="Tracé_4493"
            data-name="trace-4493"
            d="M-1488.363-225.013l-1.185,8.353c-.307.043-1.062.086-2.269.132s-2.249.064-3.125.064q-13.482,0-20.388-5.622t-6.906-17.266q0-7.3.1-15.653t.1-20.716h-11.049v-7.7h11.64V-299h9.539v15.586h23.545v7.7h-23.545q0,1.381-.1,8.219t-.1,10.129v16.311q0,8.554,4.6,12.331t14.208,3.785c.788,0,1.73-.013,2.827-.034S-1488.671-225.013-1488.363-225.013Z"
            transform="translate(1863.129 307.49)"
            fill="#282828"
          />
          <path
            id="Tracé_4494"
            data-name="trace-4494"
            d="M-1471.179-294.142h-11.576v-11.313h11.576Zm-.592,79.319h-10.392q.064-1.05.167-12.955t.1-19.666q0-6.775-.035-14.9t-.23-19.041h10.392q-.132,9.472-.229,17.725t-.1,14.7q0,15.326.163,24.6T-1471.771-214.823Z"
            transform="translate(1879.008 305.455)"
            fill="#282828"
          />
          <path
            id="Tracé_4495"
            data-name="trace-4495"
            d="M-1393.982-284.338q-8.617,19.543-18.254,41.422t-9.963,22.6h-7.694q-.331-.789-9.6-21.386t-18.877-41.982l9.642-4.375,23.112,54.789h.133l22.446-54.657Z"
            transform="translate(1886.7 310.944)"
            fill="#282828"
          />
          <path
            id="Tracé_4496"
            data-name="trace-4496"
            d="M-1341.078-255.9h-47.551a28.927,28.927,0,0,0,2.958,12.791,24.064,24.064,0,0,0,8.881,9.636,29.03,29.03,0,0,0,8.681,3.649A48.674,48.674,0,0,0-1356.4-228.6q3.748,0,6.874-.2t3.847-.263l-1.184,8.746q-.528.069-3.486.23t-6.972.165a55.441,55.441,0,0,1-18.318-2.728,34.718,34.718,0,0,1-12.791-7.531,34,34,0,0,1-7.268-10.261,31.681,31.681,0,0,1-2.993-13.876,38.826,38.826,0,0,1,2.238-13.55,36.614,36.614,0,0,1,6.708-11.247,25.924,25.924,0,0,1,9.634-6.9,30.4,30.4,0,0,1,11.939-2.435,29.825,29.825,0,0,1,10.851,1.842,22.008,22.008,0,0,1,8.222,5.592,28.088,28.088,0,0,1,5.919,10.358A48.388,48.388,0,0,1-1341.078-255.9Zm-10.194-7.366q-1.054-8.154-5.558-12.793t-11.871-4.637a17.132,17.132,0,0,0-12.3,4.7,25.1,25.1,0,0,0-6.84,12.729Z"
            transform="translate(1905.529 310.818)"
            fill="#282828"
          />
        </g>
        <path
          id="Tracé_4497"
          data-name="trace-4497"
          d="M-1748.567-290.278c-26.668-19.876-24.778-58.513-24.778-58.513V-500.909a88.879,88.879,0,0,0-15.248-6.392v168.2s-.357,35.168,26.863,58.965c33.258,29.075,74.477,20.029,74.477,20.029s-7.771-8.665-11.547-13.725C-1713.245-274.982-1728.02-274.966-1748.567-290.278Z"
          transform="translate(0 0)"
          fill="#dc082f"
        />
        <g id="Groupe_58" data-name="Groupe 58" transform="translate(-1697.331 -409.481)">
          <path
            id="Tracé_4498"
            data-name="trace-4498"
            d="M-1598.008-353.31a75.235,75.235,0,0,0,71.311,51.254c38.024,0,70.444-27.782,74.375-65.9h-15.449a59.653,59.653,0,0,1-59.148,51.945c-30.7,0-57.907-25.224-61.2-55.047Z"
            transform="translate(1757.458 452.465)"
            fill="#dc082f"
          />
          <path
            id="Tracé_4499"
            data-name="trace-4499"
            d="M-1576.023-325.557c13.469-23.149,19.781-34.138,30.083-51.174,11.373-18.8,21.647-39.033,57.261-39.033a59.655,59.655,0,0,1,54.952,36.424l-52.1.412v13.989h72.234a75.211,75.211,0,0,0-74.862-68c-33.511,0-57.352,23.065-69.61,46.685l-34.269,59.527a59.618,59.618,0,0,1-51.9,30.242,59.638,59.638,0,0,1-59.638-59.639,59.638,59.638,0,0,1,59.638-59.639,59.654,59.654,0,0,1,54.87,36.23l.53,1.382-56.083-.13v13.343h62.154l10.245-16.194a75.237,75.237,0,0,0-71.493-51.808,75.2,75.2,0,0,0-75.2,75.205,75.2,75.2,0,0,0,75.2,75.2C-1611.9-282.532-1591.358-299.2-1576.023-325.557Z"
            transform="translate(1719.218 432.941)"
            fill="#dc082f"
          />
        </g>
        <path
          id="Tracé_4500"
          data-name="trace-4500"
          d="M-1317.388-339.1V-507.3a88.88,88.88,0,0,0-15.247,6.392v152.118s.328,36.7-24.778,58.513c-12.566,10.92-22.9,15.261-39.843,15.261a59.581,59.581,0,0,1-59.581-59.581,59.581,59.581,0,0,1,59.581-59.581c15.483,0,28.913,5.3,39.507,14.986l10.251-14.99a75.632,75.632,0,0,0-49.509-18.358,75.939,75.939,0,0,0-75.939,75.94c0,35.86,24.575,66.922,58,74.882,5.152,1.257,39.706,10.462,70.555-18.026C-1317.388-304.685-1317.388-339.1-1317.388-339.1Z"
          transform="translate(99.584 0)"
          fill="#dc082f"
        />
        <path
          id="Tracé_8088"
          data-name="trace-8088"
          d="M-1748.567-290.278c-26.668-19.876-24.778-58.513-24.778-58.513V-500.909a88.879,88.879,0,0,0-15.248-6.392v168.2s-.357,35.168,26.863,58.965c33.258,29.075,74.477,20.029,74.477,20.029s-7.771-8.665-11.547-13.725C-1713.245-274.982-1728.02-274.966-1748.567-290.278Z"
          transform="translate(0 0)"
          fill="#dc082f"
        />
        <g id="Groupe_106" data-name="Groupe 106" transform="translate(-1697.331 -409.481)">
          <path
            id="Tracé_4498-2"
            data-name="trace-4498"
            d="M-1598.008-353.31a75.235,75.235,0,0,0,71.311,51.254c38.024,0,70.444-27.782,74.375-65.9h-15.449a59.653,59.653,0,0,1-59.148,51.945c-30.7,0-57.907-25.224-61.2-55.047Z"
            transform="translate(1757.458 452.465)"
            fill="#dc082f"
          />
          <path
            id="Tracé_4499-2"
            data-name="trace-4499"
            d="M-1576.023-325.557c13.469-23.149,19.781-34.138,30.083-51.174,11.373-18.8,21.647-39.033,57.261-39.033a59.655,59.655,0,0,1,54.952,36.424l-52.1.412v13.989h72.234a75.211,75.211,0,0,0-74.862-68c-33.511,0-57.352,23.065-69.61,46.685l-34.269,59.527a59.618,59.618,0,0,1-51.9,30.242,59.638,59.638,0,0,1-59.638-59.639,59.638,59.638,0,0,1,59.638-59.639,59.654,59.654,0,0,1,54.87,36.23l.53,1.382-56.083-.13v13.343h62.154l10.245-16.194a75.237,75.237,0,0,0-71.493-51.808,75.2,75.2,0,0,0-75.2,75.205,75.2,75.2,0,0,0,75.2,75.2C-1611.9-282.532-1591.358-299.2-1576.023-325.557Z"
            transform="translate(1719.218 432.941)"
            fill="#dc082f"
          />
        </g>
        <path
          id="Tracé_8089"
          data-name="trace-8089"
          d="M-1317.388-339.1V-507.3a88.88,88.88,0,0,0-15.247,6.392v152.118s.328,36.7-24.778,58.513c-12.566,10.92-22.9,15.261-39.843,15.261a59.581,59.581,0,0,1-59.581-59.581,59.581,59.581,0,0,1,59.581-59.581c15.483,0,28.913,5.3,39.507,14.986l10.251-14.99a75.632,75.632,0,0,0-49.509-18.358,75.939,75.939,0,0,0-75.939,75.94c0,35.86,24.575,66.922,58,74.882,5.152,1.257,39.706,10.462,70.555-18.026C-1317.388-304.685-1317.388-339.1-1317.388-339.1Z"
          transform="translate(99.584 0)"
          fill="#dc082f"
        />
      </g>
    </svg>
  );
}
