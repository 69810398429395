import React, { useContext } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { GlobalContext } from '../../../GloabalContext/GlobalContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      '&:hover': {
        background: '#600101',
      },
    },
  }),
);

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const { isProfessor, userName } = useContext(GlobalContext);

  function professorDisconnect() {
    room?.disconnect();
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    if (isProfessor) {
      fetch(process.env.REACT_APP_BACK_URL + 'room/disconnect/' + userName + '/' + room?.sid, requestOptions)
        .then(res => res.json())
        .then(res => console.log(res)).catch(er =>
        console.log(er),
      );
    }
  }

  return (
    <Button onClick={() => professorDisconnect()} className={clsx(classes.button, props.className)}
            data-cy-disconnect>
      Disconnect
    </Button>
  );
}
