import React, { useState, useEffect } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography, Grid, CircularProgress } from '@material-ui/core';
import jwt from 'jsonwebtoken';

function LoadingRoom() {
  return (
    <>
      <Grid container justifyContent='center' alignItems='center' direction='column' style={{ height: '100%' }}>
        <div>
          <CircularProgress variant='indeterminate' />
        </div>
        <div>
          <Typography variant='body2' style={{ fontWeight: 'bold', fontSize: '16px' }}>
            Joining a room
          </Typography>
        </div>
      </Grid>
    </>
  );
}

interface ParamTypes {
  token: string;
}

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

interface PreJoinScreensProps {
  setProfessor: (b: boolean) => void;
  setUserName: (b: string) => void;

}

export default function PreJoinScreens({ setProfessor, setUserName }: PreJoinScreensProps) {
  const { getAudioAndVideoTracks } = useVideoContext();
  const { token } = useParams<ParamTypes>();

  const [step, setStep] = useState(Steps.roomNameStep);

  const [name, setName] = useState<string>('');
  const [roomName, setRoomName] = useState<string>('any thing');

  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    setTimeout(() => {
      const {
        grants: {
          identity = '',
          video: { room = '' },
        },
      } = JSON.parse(JSON.stringify(jwt.decode(token)));
      setRoomName(room);
      setName(identity);
      setProfessor(false);
      setUserName(identity);
      if (identity.slice(0, 3) === 'pr_') {
        setName(identity.slice(3));
        setUserName(identity.slice(3));
        setProfessor(true);
      }

      setStep(Steps.deviceSelectionStep);
    }, Math.floor(Math.random() * 500) + 500);
  }, [token]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  return (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />
      {step === Steps.roomNameStep && <LoadingRoom />}
      {step === Steps.deviceSelectionStep &&
      <DeviceSelectionScreen name={name} roomName={roomName} token={token} />}
    </IntroContainer>
  );
}
